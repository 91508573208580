import RoyalProject from '../Resources/Projects/RoyalProject.png';
import MemoryProject from '../Resources/Projects/MemoryGame.png';
import YanivProject from '../Resources/Projects/YanivGame.png';
import closebtn from '../Resources/close.png';
import Footer from './Footer';
function Projectlist(){
    let projects = [
        {
            "name":"Royal Jewerly Shop",
            "img":RoyalProject,
            "info": "Royal is an online jewelry store encompassing both server and client side development with modern web technologies including HTML, CSS, JavaScript, Node.js, Express, and MongoDB. ",
            "link":"https://royal-project.onrender.com/",
            "git": "https://github.com/xHALFON/Royal_Project"
        },
        {
            "name":"Yaniv Game",
            "img":YanivProject,
            "info": "Yaniv is an engaging card game developed using technologies such as HTML, CSS, JavaScript, Node.js, Express, and MongoDB. In Yaniv, players aim to create card combos to achieve the lowest score. ",
            "link": "https://yaniv-project.onrender.com/",
            "git": "https://github.com/xHALFON/Yaniv_Project"
        },
        {
            "name":"Memory Game",
            "img":MemoryProject,
            "info": "The Memory Card Game is a web-based game developed with HTML, CSS, and JavaScript. Players flip cards to match pairs, and in two-player mode, they compete to discover the most pairs.",
            "link": "https://xhalfon.github.io/Memory_Game/",
            "git": "https://github.com/xHALFON/Memory_Game"
        },
    ];
    function renderproject(index){
        setTimeout(() => {
            document.getElementById("projectcont"+index).style.opacity = 1;
        }, 1);
    }
    function close(){
        document.getElementById("projectbanner").style.animation = "0.5s SettingsZoomout";
        document.getElementById("projectbanner").style.display = "none";
    }
    function clipProj(project){
        if(window.screen.width > "1300"){
            var x = document.getElementById('projectbanner');
            while (x.firstChild) {
                x.removeChild(x.firstChild);
            }
            x.style.display = "block";
            var banner = document.getElementById('projectbanner');
            banner.style.animation = "1s SettingsZoomin";
            var exitbtn = banner.appendChild(document.createElement("span"));
            exitbtn.setAttribute("class","bannerexitbtn");
            exitbtn.addEventListener("click", close);
            exitbtn.innerHTML = `<img src=${closebtn} class="bannerexitpic"></img>`
            
            var projectname = banner.appendChild(document.createElement("div"));
            projectname.setAttribute('class','bannername');
            projectname.innerHTML = `<center><p>${project.name}</p></center>`;

            var projectpic = banner.appendChild(document.createElement("div"));
            projectpic.setAttribute("class", "bannerpic");
            projectpic.innerHTML = `<center><img src=${project.img} class="bannercompic"></img></center>`

            var projectdet = banner.appendChild(document.createElement("div"));
            projectdet.setAttribute("class", "bannerdet");
            projectdet.innerHTML = `<center><p>${project.info}</p>
            <div class="projectlinks" style="margin-top: 20px;">
                                    <a href=${project.link} target="_blank">
                                    <div class="Circlebtn" Style="width:40px; height:40px;">
                                        <i class="fa-regular fa-circle-play" Style="font-size: 22px;"></i>
                                    </div>
                                    </a>
                                    <a href=${project.git} target="_blank">
                                    <div class="Circlebtn" Style="width:40px; height:40px;">
                                            <i class="fa-brands fa-github" Style="font-size: 25px;"></i>
                                    </div>
                                    </a>
            </div></center>
            `
        }
    }
    return(
    <section>
            <div class="mainprojectcont">
                <div class="project_title">
                    <center><h1>My <span Style="color:red;">Projects</span></h1></center>
                </div>
                <div class="bannercontainer">
                    <div class="projectbanner" id="projectbanner">
                    </div>
                </div>
                    <center><div className="projects">
                        {projects.map((Project,index) =>(
                            <div className="projectcont" id={"projectcont"+index}>
                                <div className="projectpic">
                                    <div class="computerborder">
                                        <img class="compborder" id={"pic"+index} src={Project.img} alt="Porject" onClick={()=>{clipProj(Project)}}></img>
                                    </div>
                                </div>
                                <div className="name">
                                    {Project.name}
                                </div>
                                <div className="info">
                                    {Project.info}
                                </div>
                                <div class="projectlinks">
                                    <a href={Project.link} target="_blank">
                                    <div class="Circlebtn" Style="width:40px; height:40px;">
                                        <i class="fa-regular fa-circle-play" Style="font-size: 22px;"></i>
                                    </div>
                                    </a>
                                    <a href={Project.git}target="_blank">
                                    <div class="Circlebtn" Style="width:40px; height:40px;">
                                            <i class="fa-brands fa-github" Style="font-size: 25px;"></i>
                                    </div>
                                    </a>
                                </div>
                                {renderproject(index)}
                            </div>
                        ))}
                </div>
                </center>
            </div>
            <center><div class="contact">
                <p>For Inquiries,
                    <span Style="color: red;">
                        <a href="https://www.linkedin.com/in/guy-halfon/" target='_blank'> Contact Me</a>
                    </span>
                </p>
            </div></center>
            <Footer />
        </section>
    );
}

export default Projectlist;