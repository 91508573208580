import React, { useState } from 'react';
import vs from "../Resources/skills/vs.png"
import intellij from "../Resources/skills/intellij.png"
import vscode from "../Resources/skills/vscode.png"
import pycharm from "../Resources/skills/pycharm.png"

function Tools(){
    let [tools,settools] = useState([
        {
            "name":"vs",
            "img":vs
        },
        {
            "name":"vscode",
            "img":vscode
        },
        {
            "name":"intellij",
            "img":intellij
        },
        {
            "name":"pycharm",
            "img":pycharm
        },   
    ]);

    return(
        <section>
            <center>
            <div className="skillsection">
                <h2>My Tools</h2>
                <div className="skills">
                    {tools.map((tool => (
                        <div className="skillcont">
                            <img class="skillimg" src={tool.img}></img>
                        </div>
                    )))}
                </div>
            </div>
            </center>
        </section>
    );
}

export default Tools;