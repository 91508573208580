import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Navbar from "./components/Navbar";
import Home from "./components/Pages/Home";
import Projects from './components/Pages/Projects';
import About from './components/Pages/About';

function App() {
  return (
    <Router>
    <div className="App">
        <div className="content">
          <Switch>
            <Route exact path="/">
            <div className="homecontent">
              <Navbar/>
              <Home/>
            </div>
            </Route>
            <Route path="/Skills">
            <div className="skillscontent">
              <Navbar/>
              <About/>
            </div>
            </Route>
            <Route path="/Projects">
            <div className="projectscontent">
              <Navbar/>
              <Projects/>
              </div>
            </Route>
            <Route path="*">
              <p>404 Page not found</p>
            </Route>
          </Switch>
          </div>
    </div>
    </Router>
  );
}

export default App;
