import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import Logo from '../Resources/GuyHalfonLogo.png';
function Navbar(){
    let [togglenav,settogglenav] = useState("dropnav");
    let [navbar, setnavbar] = useState("fa-solid fa-bars")
    function toggle(){
        if(togglenav == "dropnav"){
            settogglenav("dropnav open");
            setnavbar("fa-solid fa-xmark");
        }else{
            settogglenav("dropnav");
            setnavbar("fa-solid fa-bars");
        }
    }
    return(
        <section>
            <div className="navbar">
                <img src={Logo} className='logo' width="200px"></img>
                <ul className="links">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/Skills">Skills</Link></li>
                    <li><Link to="/Projects">Projects</Link></li>
                </ul>
               <div class="rightnav">
               <a href="https://github.com/xHALFON" target='_blank'>
                    <div class="Circlebtn" Style="width:40px; height:40px;">
                        <i class="fa-brands fa-github" Style="font-size: 25px;"></i>
                        
                    </div>
                </a>
                <a href="https://www.linkedin.com/in/guy-halfon/" target='_blank'>
                    <div class="Circlebtn" Style="width:40px; height:40px;">
                        <i class="fa-brands fa-linkedin" Style="font-size: 25px;"></i>
                    </div>
                </a>
                </div>
                <div className="togglebtn" onClick={toggle}>
                <i class={navbar}></i>
                </div>
            </div>
            
            <div className={togglenav}>
                    <li><Link to="/" onClick={toggle}>Home</Link></li>
                    <li><Link to="/Skills" onClick={toggle}>Skills</Link></li>
                    <li><Link to="/Projects" onClick={toggle}>Projects</Link></li>
                    <li><a href="https://github.com/xHALFON">
                    <div class="Circlebtn" Style="width:40px; height:40px;">
                        <i class="fa-brands fa-github" Style="font-size: 25px;"></i>
                        
                    </div>
                </a>
                <a href="https://www.linkedin.com/in/guy-halfon/">
                    <div class="Circlebtn" Style="width:40px; height:40px;">
                        <i class="fa-brands fa-linkedin" Style="font-size: 25px;"></i>
                    </div>
                </a></li>
            </div>
        </section>
    );
}

export default Navbar;