import React from 'react';
import Projectlist from '../Projectslist';
function Projects(){
    return(
        <section>
            <Projectlist/>
        </section>
    );
}

export default Projects