import React from 'react'
import computerpic from '../Resources/computerpic.png'
import {Link} from 'react-router-dom'
function Homesection(){

    return(
        <section>
        <div className="welcometext">
            <div className="section1">
                <p>
                    <h1><center>Welcome to my Portfolio <span Style='font-size:40px;'>&#128214;</span><hr></hr></center></h1>
                </p>
                <p>
                    <h2><center>
                        My name is <span Style="Color: red;">Guy</span> and I am 2nd Year Student<br></br>
                        B.Sc in Computer Science<span Style='font-size:25px;'>&#128187;</span></center>
                        <center>
                        <Link to="/Projects" className="projectbtn">My Projects</Link></center>
                        <p className='bigsentence'>
                            <span></span>
                        </p>
                    </h2>
                </p>
            </div>
            <div class="section2">
                <img src={computerpic} className="compic"></img>
            </div>
        </div>
        </section>
    );
}

export default Homesection;