import React from 'react';
import Skills from '../Skills';
import Gitcode from '../Gitcode';
import Footer from '../Footer';
import Tools from '../Tools';
function About(){

    return(
        <div>
            <Gitcode />
            <Skills />
            <Tools/>
            <Footer/>
        </div>
    );
}

export default About