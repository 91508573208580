import React from 'react'

function Connect(){
    return(
        <section>
            <center>
            <div class="connect">
                <p>Find Me <span Style='font-size:30px;'>&#9749;</span><br></br>
                <a href="https://github.com/xHALFON" target='_blank'>
                    <div class="Circlebtn">
                        <i class="fa-brands fa-github" Style="font-size: 30px;"></i>
                        
                    </div>
                </a>
                <a href="https://www.linkedin.com/in/guy-halfon/" target='_blank'>
                    <div class="Circlebtn">
                        <i class="fa-brands fa-linkedin" Style="font-size: 30px;"></i>
                    </div>
                </a>
                </p>
            </div>
            </center>
        </section>
    );
}

export default Connect;