import React from 'react'

function Introduction(){
    
    return(
        <section>
            <div className='introcont'>
                <div className="intro">
                    <h1>A bit About me <span Style='font-size:45px;'>&#128269;</span></h1><br></br>
                    <p><h3>
                    Computer science student with a strong motivation and determination. Skilled in C++, JavaScript, Node.js, Python, Git, and SQL. My passion for technology drives me to constantly expand my knowledge and skills in programming and software development. I am enthusiastic about tackling challenging problems and am eager to contribute to innovative projects in the field of computer science.
                    </h3></p>
                </div>
            </div>
        </section>
    );
}

export default Introduction;