import React from 'react'

function Footer(){

    return(
        <section>
            <div className="footer">
                <div>
                Designed and Developed by Guy Halfon
                </div>
                <div>
                    Copyright © 2024 <span Style="color: red;">guyhalfon</span>
                </div>
                <div>
                <a href="https://github.com/xHALFON">
                    <div class="Circlebtn" Style="width:30px; height:30px;" target='_blank'>
                        <i class="fa-brands fa-github" Style="font-size: 20px;"></i>
                        
                    </div>
                </a>
                <a href="https://www.linkedin.com/in/guy-halfon/" target='_blank'>
                    <div class="Circlebtn" Style="width:30px; height:30px;">
                        <i class="fa-brands fa-linkedin" Style="font-size: 20px;"></i>
                    </div>
                </a>
                </div>
            </div>
        </section>
    );
}

export default Footer;