import React from 'react'

function Gitcode(){
    return(
        <section>
            <link rel="stylesheet" href="http://lengthylyova.pythonanywhere.com/static/gh-contrib-graph/gh.css"></link>
            <center>
            <div className="gitcode">
                <h1>Days I code</h1><br></br>
                <p><img className='gitcodeimg' src="https://ghchart.rshah.org/ff0000/xHALFON" alt="GuyHalfon"></img></p>
                <p></p>
                <div id="gh" data-login="xHALFON"></div>
            </div>
            </center>
            <script src="http://lengthylyova.pythonanywhere.com/static/gh-contrib-graph/gh.js"></script>
        </section>
    );
}

export default Gitcode