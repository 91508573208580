import React, { useState } from 'react';
import c from "../Resources/skills/good/C.png"
import cpp from "../Resources/skills/good/C++.png"
import java from "../Resources/skills/good/java.png"
import Python from "../Resources/skills/good/Python.png"
import css from "../Resources/skills/good/CSS.png"
import html from "../Resources/skills/good/HTML.png"
import git from "../Resources/skills/good/Git.png"
import github from "../Resources/skills/good/Github.png"
import sql from "../Resources/skills/good/SQL.png"
import Linux from "../Resources/skills/good/Linux.png"
import Mongodb from "../Resources/skills/good/Mongodb.png"
import nodejs from "../Resources/skills/good/nodejs.png"
import react from "../Resources/skills/good/React.png"
import js from "../Resources/skills/good/JS.png"
function Skills(){
    let [skills,setskills] = useState([
        {
            "name":"C",
            "img":c
        },
        {
            "name":"java",
            "img":java
        },
        {
            "name":"cpp",
            "img":cpp
        },
        {
            "name":"Python",
            "img":Python
        },
        {
            "name":"git",
            "img":git
        },
        {
            "name":"html",
            "img":html
        },
        {
            "name":"css",
            "img":css
        },
        {
            "name":"JS",
            "img":js
        },
        {
            "name":"github",
            "img":github
        },
        {
            "name":"SQL",
            "img":sql
        },
        {
            "name":"Linux",
            "img":Linux
        },
        {
            "name":"Mongodb",
            "img":Mongodb
        },
        {
            "name":"nodejs",
            "img":nodejs
        },
        {
            "name":"react",
            "img":react
        },
        
    ]);

    return (
        <section>
            <center>
            <div className="skillsection">
                <h2>My Professional Skills</h2>
                <div className="skills">
                    {skills.map((skill => (
                        <div className="skillcont">
                            <img class="skillimg" src={skill.img}></img>
                        </div>
                    )))}
                </div>
            </div>
            </center>
        </section>
    );
}

export default Skills;