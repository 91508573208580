import React from 'react';
import Homesection from '../Homesection';
import Introduction from '../Introduction';
import Connect from '../Connect';
import Footer from '../Footer';


function Home(){

    return(
        <section>
                <Homesection />
                <Introduction />
                <Connect />
                <Footer />
        </section>
    );
}

export default Home